import { useMemoizedContentGetter } from 'utils/contentful'
import { FlatCarousel } from 'components/Carousels/FlatCarousel'
import { object } from 'yup'
import { carouselItemSchema } from 'constants/validation-types'

let contentSchema = object({
  carousel: object({
    carouselItems: carouselItemSchema,
  }),
})

function TestimonialCarousel({ content }) {
  let memoizedContent = useMemoizedContentGetter(content, ['carousel'])

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('TestimonialCarousel - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const {
    carousel: { carouselItems },
  } = memoizedContent

  return <FlatCarousel carouselItems={carouselItems} idPrefix="home" />
}

export { TestimonialCarousel }
