import { PageTitle } from 'pageSections/PageTitle'
import { StickySubNavWrapper } from 'pageSections/StickySubNavWrapper'

function PoliciesBody({ content }) {
  return <StickySubNavWrapper content={content} />
}

function PoliciesHero({ content }) {
  return <PageTitle content={content} />
}

export { PoliciesBody, PoliciesHero }
