import { TitleDivider } from 'components/TitleDivider'
import { VideoPlayer } from 'components/VideoPlayer'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import React from 'react'
import styles from './AboutUsVideo.module.scss'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'

function AboutUsVideo({ content }) {
  const { title, subtitle, videoPlayer } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'videoPlayer',
  ])

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const variant = {
    title: isMobile ? 'displayS30' : 'displayM20',
    titleFontWeight: 'bold',
    subtitle: isMobile ? 'bodyS30' : 'bodyM10',
    subtitleFontWeight: 'regular',
  }

  return (
    <Section className={styles['section']}>
      <TitleDivider
        title={title}
        subtitle={subtitle}
        isMobile={isMobile}
        variants={variant}
      ></TitleDivider>
      <VideoPlayer content={videoPlayer} />
    </Section>
  )
}

export { AboutUsVideo }
